<template>
<div>
  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5> Detalles de Trituración </h5> </div>
  <my-breadcrumbs :routes="routes"/>

  <!-- BOTONERA -->
  <a-row class="mt-3" align="middle" type="flex" justify="end">
    <a-col class="mr-4">
      <AddQuantityDetailAndCloseBatchable v-if="crushingId"
                                          :batchable_id="crushingId"
                                          batchable_type="crushings"
                                          :status="crushing.status"
                                          @Updated="FetchCrushing"/>
    </a-col>
  </a-row>
  <!-- BOTONERA -->
  <!-- Información general de crushing -->
  <a-descriptions v-if="Object.keys(crushing).length > 1" :column="{ sm: 1, md: 2 }"
                  :title="`ID Trituración: ${crushing.code}`"
                  bordered size="small">
    <a-descriptions-item label="Estatus">
      <strong>{{crushing.status}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Horas Trabajadas">
      <strong>{{crushing.staff_time}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Cantidad Total de Lote Terminado">
      <strong>{{crushing.total_quantity}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Contenedores Totales">
      <strong>{{crushing.total_containers}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Lote Terminado">
      <strong v-if="crushing.batch">{{crushing.batch.code}}</strong>
    </a-descriptions-item>
  </a-descriptions>
  <!-- Información general de crushing -->
  <a-row class="mt-2 py-4 px-2 bg-white">
    <div class="col-12">
      <a-tabs type="card" v-model="activeTabKey">
        <a-tab-pane key="0">
          <span slot="tab"><a-icon type="table"/> Formulaciones</span>
          <!-- TABLA Formulacions linked to crushings -->
          <a-table class="bg-gray-3"
                   :columns="innerCrushingCols"
                   :data-source="crushing.formulations"
                   :pagination="false"
                   :row-key="record => record.id">
            <template slot="info" slot-scope="data">
              <formulationDescription :formulation="data"/>
            </template>
          </a-table>
          <!-- TABLA Formulacions linked to crushings -->
        </a-tab-pane>
        <a-tab-pane key="1">
          <span slot="tab"><a-icon type="user"/> Personal</span>
          <div class="card">
            <div class="card-body text-center">
              <personalTab :work-center="filterPlant"
                           :staffable-id="crushingId"
                           staffable-type="crushings"
                           :locked="lockedByStatus"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab"><a-icon type="form"/> Operación</span>
          <operationTab :crushing-id="crushingId" :locked="lockedByStatus" :crushing-type="crushingType"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab"><a-icon type="rest" />Lote terminado</span>
          <div class="card">
            <div class="card-body text-center">
              <batchFinishedTab :batchable_id="crushingId" batchable_type="crushings" :locked="!lockedByStatus"/>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-row>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
import { crushingStatuses } from '@/constant/crushings'
import personalTab from '../formulations/components/production_order_scheduling_tabs/personal-tab'
import operationTab from './components/operationTab'
import batchFinishedTab from '../formulations/components/production_order_scheduling_tabs/batch-finished-tab'
import formulationDescription from '../formulations/components/formulationDescription'
import AddQuantityDetailAndCloseBatchable from '../formulations/components/AddQuantityDetailAndCloseBatchable'
export default {
  name: 'crushingForm',
  mixins: [fractalMixin],
  components: {
    personalTab,
    formulationDescription,
    operationTab,
    batchFinishedTab,
    AddQuantityDetailAndCloseBatchable,
  },
  data() {
    return {
      // Constantes
      crushingStatuses: crushingStatuses,
      crushingId: this.$route.params.id,
      crushingType: this.$route.params.type,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          breadcrumbName: 'Trituraciones',
          path: `/trituraciones/${this.$route.params.type}`,
        },
        {
          breadcrumbName: 'Trituración',
          aIcon: '',
        },
      ],
      activeTabKey: '0',
      crushing: {
        formulations: [],
        status: crushingStatuses.abierto,
      },
      innerCrushingCols: [
        {
          title: 'Formulaciones',
          dataIndex: '',
          class: 'text-center',
          scopedSlots: { customRender: 'info' },
        },
      ],
    }
  },
  computed: {
    ...mapState('productionOrders', ['selectedWC']),
    filterPlant: {
      get() {
        return this.selectedWC
      },
      set(value) {
        this.SAVE_WORK_CENTER(value)
      },
    },
    lockedByStatus() {
      return this.crushing.status !== this.crushingStatuses.abierto
    },
  },
  methods: {
    ...mapActions('productionOrders', ['SAVE_WORK_CENTER', 'RESET_WORK_CENTER']),
    async FetchCrushing() {
      // Consulta de crushing via api
      this.crushing = await this.GetResource(`/crushings/${this.crushingId}`, {
        include: 'formulations.operationShifts,formulations.formulationTypes,formulations.processTypes,formulations.technicalPrimes,formulations.technicalSecondaries,formulations.workCenters',
      })
      // Set de workcenter en estado segun formulación
      this.SAVE_WORK_CENTER(this.crushing.formulations[0].workCenters.id)
    },
  },
  async mounted() {
    await this.FetchCrushing()
  },
}
</script>

<style scoped>

</style>
