<template>
  <div>
    <b-button v-if="crushingOperations.crushingProductions !== null"
              @click="OpenCrushingProductionsModal(crushingOperations.id,crushingOperations.crushingProductions)"
              variant="info" size="sm" pill>
      <b-icon icon="pencil"></b-icon>
    </b-button>
    <b-button v-else @click="OpenCrushingProductionsModal(crushingOperations.id)"
              variant="info" size="sm" pill>
      <b-icon icon="plus"></b-icon>
    </b-button>
    <!-- crushingProductions-  MODAL -->
    <b-modal title="Detalle de Producción" ref="crushingProductionsModal" size="lg" @hide="CloseCrushingProductionsModal" >
      <b-container fluid v-show="!spinnerLoad">
        <!--FORMULARIO-->
        <a-form-model :model="crushingProductions.form" :rules="crushingProductionRules" ref="crushingProductionForm"
                      layout="vertical" :wrapper-col="{ span: 24}">
          <template v-if="crushingType === 'llantas'">
            <h6 class="text-secondary">Cálculo de Toneladas a Triturar</h6><hr>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 24 }" :lg="{ span:8}">
                <!--NUMBER INPUT - Base del Cono(m)-->
                <a-form-model-item label="Base del Cono(m)" ref="base_cono"  prop="base_cono" >
                  <a-input-number v-model="crushingProductions.form.base_cono" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Base del Cono(m) -->
              </a-col>
              <a-col :md="{ span: 24 }" :lg="{ span:8}">
                <!--NUMBER INPUT - Altura del Cono(m)-->
                <a-form-model-item label="Altura del Cono(m)" ref="altura_cono"  prop="altura_cono" >
                  <a-input-number v-model="crushingProductions.form.altura_cono" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Altura del Cono(m) -->
              </a-col>
              <a-col :md="{ span: 24 }" :lg="{ span:8}">
                <!--NUMBER INPUT - Toneladas Descargadas-->
                <a-form-model-item label="Toneladas Descargadas" ref="ton_descargadas"  prop="ton_descargadas" >
                  <a-input-number v-model="crushingProductions.form.ton_descargadas" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Toneladas Descargadas -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 24 }" :lg="{ span:8}">
                <!--NUMBER INPUT - Densidad-->
                <a-form-model-item label="Densidad" ref="density"  prop="density" >
                  <a-input-number v-model="crushingProductions.form.density" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="0.5" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Densidad -->
              </a-col>
              <a-col :md="{ span: 12 }" :lg="{ span: 8}">
                <!--NUMBER INPUT - TON (Cálculo)-->
                <a-form-model-item label="TON (Cálculo)"
                                   ref="ton"  prop="ton" >
                  <a-input-number v-model="crushingProductions.form.ton"
                                  style="width: 100%"
                                  disabled size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -TON (Cálculo) -->
              </a-col>
              <a-col :md="{ span: 12}" :lg="{ span: 8}">
                <!--NUMBER INPUT - Volumen (Cálculo)-->
                <a-form-model-item label="Volumen (Cálculo)"
                                   ref="volumen"  prop="volumen" >
                  <a-input-number v-model="crushingProductions.form.volumen"
                                  style="width: 100%"
                                  disabled size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Volumen (Cálculo) -->
              </a-col>
            </a-row>
            <h6 class="text-secondary">Total de Llantas pasadas por Debeader</h6><hr>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 12, offset:6 }" :lg="{ span:8, offset:8}">
                <!--NUMBER INPUT - Total de Llantas pasadas por Debeader-->
                <a-form-model-item label="Total de Llantas pasadas por Debeader" ref="total_llantas_debeader"  prop="total_llantas_debeader" >
                  <a-input-number v-model="crushingProductions.form.total_llantas_debeader" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Total de Llantas pasadas por Debeader -->
              </a-col>
            </a-row>
          </template>
          <template v-else-if="crushingType === 'solidos'">
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 24 }" :lg="{ span:12}">
                <!--NUMBER INPUT - Volumen AFR1 Material Pre-procesado(t)-->
                <a-form-model-item label="Volumen AFR1 Material Pre-procesado(t)" ref="vol_afr1"  prop="vol_afr1" >
                  <a-input-number v-model.number="crushingProductions.form.vol_afr1" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Volumen AFR1 Material Pre-procesado(t) -->
              </a-col>
              <a-col :md="{ span: 24 }" :lg="{ span:12}">
                <!--NUMBER INPUT - Volumen AFR2 Material Pre-procesado(t)-->
                <a-form-model-item label="Volumen AFR2 Material Pre-procesado(t)" ref="vol_afr2"  prop="vol_afr2" >
                  <a-input-number v-model.number="crushingProductions.form.vol_afr2" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Volumen AFR2 Material Pre-procesado(t) -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 12, offset:6 }" :lg="{ span: 8, offset:8}">
                <!--NUMBER INPUT - Volumen Pre-procesado Total(t)-->
                <a-form-model-item label="Volumen Pre-procesado Total(t)" :extra="preProcesadoTotal"
                                   ref="vol_preprocesado"  prop="vol_preprocesado" >
                  <a-input-number v-model="crushingProductions.form.vol_preprocesado" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Volumen Pre-procesado Total(t) -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 8 }" :lg="{ span:10}">
                <!--NUMBER INPUT - Volumen total rechazado-->
                <a-form-model-item label="Volumen total rechazado" ref="vol_total"  prop="vol_total" >
                  <a-input-number v-model="crushingProductions.form.vol_total" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Volumen total rechazado -->
              </a-col>
              <a-col :md="{ span: 8 }" :lg="{ span:10}">
                <!--NUMBER INPUT - Promedio diario NCV (GJ/t)-->
                <a-form-model-item label="Promedio diario NCV (GJ/t)" ref="promedio_ncv"  prop="promedio_ncv" >
                  <a-input-number v-model="crushingProductions.form.promedio_ncv" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Promedio diario NCV (GJ/t) -->
              </a-col>
              <a-col :md="{ span: 8 }" :lg="{ span:3, offset:1}">
                <!--NUMBER INPUT - BDP Válido-->
                <a-form-model-item label="BDP Válido" ref="bdp_valido"  prop="bdp_valido" >
                  <a-switch v-model="crushingProductions.form.bdp_valido" :disabled="locked"
                            style="width: 100%"
                            checked-children="SI" un-checked-children="NO"/>
                </a-form-model-item>
                <!--NUMBER INPUT -BDP Válido -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center">
              <a-col :md="{ span: 24 }" :lg="{ span:12}">
                <!--NUMBER INPUT - Consumo de Combustible para equipo móvil-->
                <a-form-model-item label="Consumo de Combustible para equipo móvil" ref="consumo_combustible"  prop="consumo_combustible" >
                  <a-input-number v-model="crushingProductions.form.consumo_combustible" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Consumo de Combustible para equipo móvil -->
              </a-col>
              <a-col :md="{ span: 24 }" :lg="{ span:12}">
                <!--NUMBER INPUT - Energía Total Consumida (KW/H)-->
                <a-form-model-item label="Energía Total Consumida (KW/H)" ref="energia_total"  prop="energia_total" >
                  <a-input-number v-model="crushingProductions.form.energia_total" :disabled="locked"
                                  style="width: 100%"
                                  :min="0" :step="1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Energía Total Consumida (KW/H) -->
              </a-col>
            </a-row>
          </template>
          <a-row v-if="['solidos', 'llantas'].includes(crushingType)"
                 class="my-2 mt-4" align="middle" :gutter="[8,24]" type="flex" justify="end">
            <b-button  variant="primary" @click="SubmitCrushingProductions" pill v-if="!locked">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
          </a-row>
        </a-form-model>
        <!--FORMULARIO-->
      </b-container>
      <my-spinner :load="spinnerLoad"/>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseCrushingProductionsModal"/>
      </template>
    </b-modal>
    <!-- crushingProductions-  MODAL -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'crushingProduction',
  mixins: [fractalMixin],
  props: {
    crushingOperations: {
      type: [Object, null],
      required: false,
      defaul: () => null,
    },
    crushingType: {
      type: [String],
      required: false,
      default: 'formulations',
      validator: (value) => ['solidos', 'llantas'].indexOf(value) !== -1,
    },
    locked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      spinnerLoad: false,
      // ----- crushingProductions -----
      crushingProductions: {
        resourceType: 'crushingProductions',
        form: {
          id: '',
          // llantas
          crushingOperations: undefined,
          base_cono: 0,
          altura_cono: 0,
          density: 0,
          ton_descargadas: 0,
          total_llantas_debeader: 0,
          volumen: 0,
          ton: 0,
          // solidos
          vol_preprocesado: 0,
          vol_afr2: 0,
          vol_afr1: 0,
          vol_total: 0,
          promedio_ncv: 0,
          mejor_practica: 0,
          bdp_valido: false,
          consumo_combustible: 0,
          energia_total: 0,
        },
        relationships: ['crushingOperations'],
      },
      crushingProductionRules: {},
    }
  },
  computed: {
    preProcesadoTotal() {
      const AFR2AFR1 = Number(this.crushingProductions.form.vol_afr2) + Number(this.crushingProductions.form.vol_afr1)
      return `Valor recomendado: ${(AFR2AFR1 > 0) ? AFR2AFR1 : 0}`
    },
  },
  methods: {
    // crushingProductions
    OpenCrushingProductionsModal(crushingOperationId, crushingProductions) {
      this.$refs.crushingProductionsModal.show()
      this.crushingProductions.form.crushingOperations = crushingOperationId.toString()
      if (crushingProductions) {
        this.crushingProductions.form.id = crushingProductions.id
        if (this.crushingType === 'solidos') {
          this.crushingProductions.form.vol_preprocesado = crushingProductions.vol_preprocesado
          this.crushingProductions.form.vol_afr2 = crushingProductions.vol_afr2
          this.crushingProductions.form.vol_afr1 = crushingProductions.vol_afr1
          this.crushingProductions.form.vol_total = crushingProductions.vol_total
          this.crushingProductions.form.promedio_ncv = crushingProductions.promedio_ncv
          this.crushingProductions.form.mejor_practica = crushingProductions.mejor_practica
          this.crushingProductions.form.bdp_valido = !!crushingProductions.bdp_valido
          this.crushingProductions.form.consumo_combustible = crushingProductions.consumo_combustible
          this.crushingProductions.form.energia_total = crushingProductions.energia_total
        } else if (this.crushingType === 'llantas') {
          this.crushingProductions.form.base_cono = crushingProductions.base_cono
          this.crushingProductions.form.altura_cono = crushingProductions.altura_cono
          this.crushingProductions.form.density = crushingProductions.density
          this.crushingProductions.form.ton_descargadas = crushingProductions.ton_descargadas
          this.crushingProductions.form.total_llantas_debeader = crushingProductions.total_llantas_debeader
          this.crushingProductions.form.volumen = crushingProductions.volumen
          this.crushingProductions.form.ton = crushingProductions.ton
        }
      } else this.crushingProductions.form.id = ''
    },
    async CloseCrushingProductionsModal() {
      this.crushingProductions.form.crushingOperations = undefined
      this.crushingProductions.form.id = ''
      Object.assign(this.crushingProductions.form, {
        id: '',
        crushingOperations: undefined,
        base_cono: 0,
        altura_cono: 0,
        density: 0,
        ton_descargadas: 0,
        total_llantas_debeader: 0,
        volumen: 0,
        ton: 0,
        vol_preprocesado: 0,
        vol_afr2: 0,
        vol_afr1: 0,
        vol_total: 0,
        promedio_ncv: 0,
        mejor_practica: 0,
        bdp_valido: false,
        consumo_combustible: 0,
        energia_total: 0,
      })
      this.$refs.crushingProductionsModal.hide()
    },
    async SubmitCrushingProductions() {
      this.$refs.crushingProductionForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.crushingProductions.form.id === '') {
            // Post
            await this.PostResource(this.crushingProductions, this.OnsuccessCrushingProductions, () => {
              this.spinnerLoad = false
            })
          } else {
            // Put
            await this.PutResource(this.crushingProductions, this.OnsuccessCrushingProductions, () => {
              this.spinnerLoad = false
            })
          }
        } else return false
      })
    },
    async OnsuccessCrushingProductions() {
      this.spinnerLoad = false
      this.CloseCrushingProductionsModal()
      this.$emit('SuccessSubmit')
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
