export const auxiliarMasterTypes = {
  movile: 'Equipos Móviles',
  laboratory: 'Equipos de Laboratorio',
  security: 'Equipos de Seguridad',
  crushings: 'Trituradoras',
  installation: 'Instalaciones',

}

export const auxiliarMasterTypesCombo = Object.values(auxiliarMasterTypes)
