<template>
<div class="mt-2">
  <!--BOTONERA  -->
  <template v-if="!locked">
    <div class="row justify-content-end mb-4 mr-4">
      <b-button pill variant="primary" @click="OpenModal()">
        <b-icon icon="plus"/> Cargar Trituradora
      </b-button>
    </div>
  </template>
  <!--BOTONERA  -->
  <!--TABLA  -->
  <a-row class="mt-2 px-1 py-2 bg-white border border-gray-2">
    <!--TABLA-->
    <a-table :columns="crushinOperationTable.columns"
              :data-source="crushinOperationTable.data"
              :pagination="crushinOperationTable.pagination"
              :loading="crushinOperationTable.loading"
              @change="FetchOperations"
              :row-key="record => record.id" bordered>
      <template slot="auxiliarMasters" slot-scope="auxiliarMasters">
          <small>{{ auxiliarMasters.code }}</small>- {{ auxiliarMasters.name }}
      </template>
      <template slot="checksEquipmentSources" slot-scope="{checksEquipmentSources, auxiliarMasters}">
        <b-button v-if="!locked" @click="GoToCheckLis(checksEquipmentSources,auxiliarMasters)"
                  variant="primary" size="sm" pill>
          {{ checksEquipmentSources.name }}
        </b-button>
        <b-button v-else @click="GoToHistory(auxiliarMasters.id)"
                  variant="info" size="sm" pill>
          <b-icon icon="clock-history"/> Bitácora
        </b-button>
      </template>
      <template slot="crushingShutdowns" slot-scope="crushingOperations">
        <b-button variant="secondary" size="sm" pill @click="GoToShutDowns(crushingOperations.id)">
          <b-icon icon="pencil"></b-icon> {{crushingOperations.crushingShutdowns.length}}
        </b-button>
      </template>
      <template slot="crushingProductions" slot-scope="crushingOperations">
        <crushingProduction @SuccessSubmit="FetchOperations"
                            :locked="locked"
                            :crushing-type="crushingType"
                            :crushing-operations="crushingOperations"/>
      </template>
    </a-table>
    <!--TABLA-->
  </a-row>
  <!--TABLA  -->
  <!-- crushingOperations- MODAL -->
  <b-modal title="Detalle de Trituración" ref="crushingOperationModal" size="lg" @hide="CloseModal" >
    <b-container fluid v-show="!spinnerLoad">
      <!--FORMULARIO-->
      <a-form-model :model="resourceObj.form" :rules="rules" ref="crushingOperationsForm"
          layout="vertical" :wrapper-col="{ span: 24}">
        <a-row class="my-2" align="middle" :gutter="[8,24]">
          <!--SELECT - -->
          <a-form-model-item label="Trituradora" ref="auxiliarMasters" prop="auxiliarMasters" >
            <ResourceSelect v-model="resourceObj.form.auxiliarMasters"
                            urlResource="/auxiliarMasters"
                            :filters="auxiliar_mater_select"
                            placeholder="Seleccionar Trituradora"
            >
              <span slot-scope="{option}">{{option.name}}</span>
            </ResourceSelect>
          </a-form-model-item>
          <!--SELECT - -->
        </a-row>
        <a-row class="my-2" align="middle" :gutter="[8,24]">
          <a-form-model-item label="Check de Arranque" ref="checksEquipmentSources" prop="checksEquipmentSources" >
            <ResourceSelect v-model="resourceObj.form.checksEquipmentSources"
                            urlResource="/checksEquipmentSources"
                            :filters="checksEquipmentSource_select"
                            placeholder="Seleccionar Check List"
            >
              <span slot-scope="{option}">{{option.name}}</span>
            </ResourceSelect>
          </a-form-model-item>
        </a-row>
        <a-row class="my-2 mt-4" align="middle" :gutter="[8,24]" type="flex" justify="end">
          <b-button  variant="primary" @click="SubmitCrushingOperation" pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/> Guardar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/>
            </div>
          </b-button>
        </a-row>
      </a-form-model>
      <!--FORMULARIO-->
    </b-container>
    <my-spinner :load="spinnerLoad"/>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- crushingOperations-  MODAL -->
  <!-- crushingProductions-  MODAL -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { auxiliarMasterTypes } from '@/constant/auxiliarMaters'
import crushingProduction from './crushingProduction'
export default {
  name: 'operationTab',
  mixins: [fractalMixin],
  components: {
    crushingProduction,
  },
  props: {
    crushingId: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    crushingType: {
      type: [String],
      required: false,
      default: 'formulations',
      validator: (value) => ['solidos', 'llantas'].indexOf(value) !== -1,
    },
    locked: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      // constans
      // selects
      auxiliar_mater_select: {
        'filter[type]': { ...auxiliarMasterTypes }.crushings,
        'filter[status]': 1,
      },
      // checks for crushings
      checksEquipmentSource_select: {
        'filter[equipment_type]': { ...auxiliarMasterTypes }.crushings,
      },
      // ----- crushingOperations -----
      // table
      crushinOperationTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Trituradora',
            dataIndex: 'auxiliarMasters',
            class: 'small text-center',
            scopedSlots: { customRender: 'auxiliarMasters' },
          },
          {
            title: 'Revisión de Arranque',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'checksEquipmentSources' },
          },
          {
            title: 'Tiempos de Paro',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'crushingShutdowns' },
          },
          {
            title: 'Producción',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'crushingProductions' },
            // width: '20%',
          },
          // {
          //   title: 'Acción',
          //   dataIndex: '',
          //   key: 'x',
          //   class: 'small text-center',
          //   scopedSlots: { customRender: 'action' },
          //   // width: '20%',
          // },
        ],
      },
      // main resource obj
      resourceObj: {
        resourceType: 'crushingOperations',
        form: {
          id: '',
          auxiliarMasters: undefined,
          checksEquipmentSources: undefined,
          crushings: undefined,
        },
        relationships: ['crushings', 'auxiliarMasters', 'checksEquipmentSources'],
      },
      rules: {
        auxiliarMasters: [
          { required: true, message: 'La trituradora es obligatoria.', trigger: 'blur' },
        ],
        checksEquipmentSources: [
          { required: true, message: 'El Check List es obligatorio.', trigger: 'blur' },
        ],
      },
      spinnerLoad: false,
    }
  },
  methods: {
    // ----- crushingOperations -----
    async FetchOperations(pagEvt) {
      const params = {
        include: 'auxiliarMasters,checksEquipmentSources,crushingProductions,crushingShutdowns',
        // include: 'auxiliarMasters',
        'filter[crushings]': this.crushingId,
      }
      await this.GetTableResource(pagEvt, 'crushingOperations', this.crushinOperationTable, params)
    },
    OpenModal() {
      this.$refs.crushingOperationModal.show()
    },
    CloseModal() {
      this.$refs.crushingOperationModal.hide()
      this.resourceObj.form.id = ''
      this.resourceObj.form.auxiliarMasters = undefined
      this.resourceObj.form.checksEquipmentSources = undefined
    },
    async SubmitCrushingOperation() {
      this.$refs.crushingOperationsForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            // Post
            await this.PostResource(this.resourceObj, this.OnsuccessCrushingOperation, () => {
              this.spinnerLoad = false
            })
          }
        } else return false
      })
    },
    async OnsuccessCrushingOperation () {
      this.spinnerLoad = false
      this.CloseModal()
      await this.FetchOperations()
    },
    // ----- crushingOperations -----
    // checkList
    GoToCheckLis(checksEquipmentSources, auxiliarMasters) {
      const routeData = this.$router.resolve({ name: 'equipmentChecks', params: { checksEquipmentSource: checksEquipmentSources.id, auxiliarMaster: auxiliarMasters.id } })
      window.open(routeData.href, '_blank')
    },
    GoToHistory(id) {
      const routeData = this.$router.resolve({ name: 'equipmentChecksHistory', params: { auxiliarMaster: id } })
      window.open(routeData.href, '_blank')
    },
    // crushingShutdowns
    GoToShutDowns(crushingOperationId) {
      const routeData = this.$router.resolve({ name: 'crushingShutdowns', params: { crushingOperationId } })
      window.open(routeData.href, '_blank')
    },
  },
  async mounted() {
    this.resourceObj.form.crushings = this.crushingId
    await this.FetchOperations()
  },
}
</script>

<style scoped>

</style>
