var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[(!_vm.locked)?[_c('div',{staticClass:"row justify-content-end mb-4 mr-4"},[_c('b-button',{attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.OpenModal()}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Cargar Trituradora ")],1)],1)]:_vm._e(),_c('a-row',{staticClass:"mt-2 px-1 py-2 bg-white border border-gray-2"},[_c('a-table',{attrs:{"columns":_vm.crushinOperationTable.columns,"data-source":_vm.crushinOperationTable.data,"pagination":_vm.crushinOperationTable.pagination,"loading":_vm.crushinOperationTable.loading,"row-key":function (record) { return record.id; },"bordered":""},on:{"change":_vm.FetchOperations},scopedSlots:_vm._u([{key:"auxiliarMasters",fn:function(auxiliarMasters){return [_c('small',[_vm._v(_vm._s(auxiliarMasters.code))]),_vm._v("- "+_vm._s(auxiliarMasters.name)+" ")]}},{key:"checksEquipmentSources",fn:function(ref){
var checksEquipmentSources = ref.checksEquipmentSources;
var auxiliarMasters = ref.auxiliarMasters;
return [(!_vm.locked)?_c('b-button',{attrs:{"variant":"primary","size":"sm","pill":""},on:{"click":function($event){return _vm.GoToCheckLis(checksEquipmentSources,auxiliarMasters)}}},[_vm._v(" "+_vm._s(checksEquipmentSources.name)+" ")]):_c('b-button',{attrs:{"variant":"info","size":"sm","pill":""},on:{"click":function($event){return _vm.GoToHistory(auxiliarMasters.id)}}},[_c('b-icon',{attrs:{"icon":"clock-history"}}),_vm._v(" Bitácora ")],1)]}},{key:"crushingShutdowns",fn:function(crushingOperations){return [_c('b-button',{attrs:{"variant":"secondary","size":"sm","pill":""},on:{"click":function($event){return _vm.GoToShutDowns(crushingOperations.id)}}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" "+_vm._s(crushingOperations.crushingShutdowns.length)+" ")],1)]}},{key:"crushingProductions",fn:function(crushingOperations){return [_c('crushingProduction',{attrs:{"locked":_vm.locked,"crushing-type":_vm.crushingType,"crushing-operations":crushingOperations},on:{"SuccessSubmit":_vm.FetchOperations}})]}}])})],1),_c('b-modal',{ref:"crushingOperationModal",attrs:{"title":"Detalle de Trituración","size":"lg"},on:{"hide":_vm.CloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('simple-modal-footer',{on:{"ClickEvent":_vm.CloseModal}})]},proxy:true}])},[_c('b-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.spinnerLoad),expression:"!spinnerLoad"}],attrs:{"fluid":""}},[_c('a-form-model',{ref:"crushingOperationsForm",attrs:{"model":_vm.resourceObj.form,"rules":_vm.rules,"layout":"vertical","wrapper-col":{ span: 24}}},[_c('a-row',{staticClass:"my-2",attrs:{"align":"middle","gutter":[8,24]}},[_c('a-form-model-item',{ref:"auxiliarMasters",attrs:{"label":"Trituradora","prop":"auxiliarMasters"}},[_c('ResourceSelect',{attrs:{"urlResource":"/auxiliarMasters","filters":_vm.auxiliar_mater_select,"placeholder":"Seleccionar Trituradora"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return _c('span',{},[_vm._v(_vm._s(option.name))])}}]),model:{value:(_vm.resourceObj.form.auxiliarMasters),callback:function ($$v) {_vm.$set(_vm.resourceObj.form, "auxiliarMasters", $$v)},expression:"resourceObj.form.auxiliarMasters"}})],1)],1),_c('a-row',{staticClass:"my-2",attrs:{"align":"middle","gutter":[8,24]}},[_c('a-form-model-item',{ref:"checksEquipmentSources",attrs:{"label":"Check de Arranque","prop":"checksEquipmentSources"}},[_c('ResourceSelect',{attrs:{"urlResource":"/checksEquipmentSources","filters":_vm.checksEquipmentSource_select,"placeholder":"Seleccionar Check List"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return _c('span',{},[_vm._v(_vm._s(option.name))])}}]),model:{value:(_vm.resourceObj.form.checksEquipmentSources),callback:function ($$v) {_vm.$set(_vm.resourceObj.form, "checksEquipmentSources", $$v)},expression:"resourceObj.form.checksEquipmentSources"}})],1)],1),_c('a-row',{staticClass:"my-2 mt-4",attrs:{"align":"middle","gutter":[8,24],"type":"flex","justify":"end"}},[_c('b-button',{attrs:{"variant":"primary","pill":""},on:{"click":_vm.SubmitCrushingOperation}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-none d-md-block",attrs:{"title":"Guardar"}},[_c('b-icon',{attrs:{"icon":"archive"}}),_vm._v(" Guardar ")],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-md-none",attrs:{"title":"Guardar"}},[_c('b-icon',{attrs:{"icon":"archive"}})],1)])],1)],1)],1),_c('my-spinner',{attrs:{"load":_vm.spinnerLoad}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }